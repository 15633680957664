/**
 * @file 日志埋点
 */
import WeirwoodOptions from '../../../weirwood.json';

class Perf {
    constructor() {
        this.regirst(document, 'script');
    };

    regirst(d: any, s: string): Element {

        const js = d.createElement(s);
        js.async = true;

        const sc = d.getElementsByTagName(s)[0];

        js.src = '//dmpstatic.cdn.bcebos.com/weirwood-sdk/1/1.2.65/bundle.min.js';

        js.onload = function () {
            const defaultOptions = {
                common: {
                    // 只支持如下字符：英文字母(大小写)，数字(0-9)，下划线(_)，中划线(-)，点(.)，@符号
                    buildid: WeirwoodOptions.buildid,
                    token: WeirwoodOptions.token,
                    ignoreUrls: [
                        // 本地开发屏蔽错误发送
                        'localhost',
                        '127.0.0.1',
                        'crmtest.baidu.com',
                        'partner.baidu-int.com',
                    ],
                },
                error: {
                    collectWindowErrors: true,
                    collectUnhandledRejections: true,
                    // 静态资源加载异常
                    collectResourceLoadErrors: true,
                },
                perf: {
                    // 性能数据PV日志会比较大，可以输入 sampleRate 进行采样，控制在 50 W左右
                    sampleRate: 0.5,
                    spa: true,
                    history: true,
                },
            };

            const win: any = window as any;

            const weirword = win.Weirwood;
            weirword.init(defaultOptions);
        };

        sc.parentNode?.insertBefore(js, sc);

        return js;
    }

}

export const hm = new Perf();
