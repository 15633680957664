/**
 * @file 指令注册
 */
import {watch} from 'vue';
import type {Directive, App} from 'vue';
import {useAuthInfoStore} from '@/store';

interface Binding {
    [propName: string]: any;
    value: string;
    oldValue: string | number;
    arg: string;
    modifiers: string;
}
interface Access {
    [propName: string]: any;
}

// 权限指令
const auth: Directive<HTMLElement> = {
    mounted: (el, {arg}: Binding | any) => {

        const {authInfo} = useAuthInfoStore();

        function renderDom(access: Access, arg: string) {
            if (!access[arg]) {
                el.style.display = 'none';
            }
            else {
                el.style.display = 'inline';
            }
        }

        const watcher = (access: Access, arg: string) => {
            if (access[arg] === undefined) {
                const stop = watch(authInfo, () => {
                    renderDom(authInfo, arg);
                    stop();
                });
            }
        };

        // 添加观察者
        watcher(authInfo, arg);

        renderDom(authInfo, arg);

    },
};



export default {
    install(app: App) {

        const direct = this.direct;

        for (let [k, v] of Object.entries(direct)) {
            app.directive(k, v);
        }
    },
    direct: {
        auth,
    },
};