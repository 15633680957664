/**
 * 代理合作合同路由
 * */
import type {RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    /**
     * 新增
     * */
    {
        path: '/agentContract/apply',
        name: 'agentContractApply',
        meta: {
            label: '新增代理合作合同',
        },
        component: () => import('@/views/agentContract/apply/index.vue'),
    },
    /**
     * 编辑
     * */
    {
        path: '/agentContract/edit',
        name: 'agentContractEdit',
        meta: {
            label: '编辑代理合作合同',
        },
        component: () => import('@/views/agentContract/apply/index.vue'),
    },
    /**
     * 申请详情
     * */
    {
        path: '/agentContract/detail',
        name: 'agentContractDetail',
        meta: {
            label: '代理合作合同详情',
        },
        component: () => import('@/views/agentContract/detail/apply/index.vue'),
    },
    /**
     * 生效详情
     * */
    {
        path: '/agentContract/versionDetail',
        name: 'agentContractVersionDetail',
        meta: {
            label: '代理合作合同详情',
        },
        component: () => import('@/views/agentContract/detail/apply/index.vue'),
        // component: () => import('@/views/agentContract/detail/version/index.vue'),
    },
];

export default routes;
