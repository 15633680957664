import {createApp} from 'vue';
import '@/plugin/index';
import ElementPlus from 'element-plus';
import OneCrmUI from '@baidu/onecrm-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import directives from './plugin/directives';

import {getTimeStr} from '@/common/utils';

import '@/styles';
import 'dayjs/locale/zh-cn';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(OneCrmUI);

// 挂载函数
app.config.globalProperties.$getTimeStr = getTimeStr;

// 注册指令
directives.install(app);


app.mount('#app');
