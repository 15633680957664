/**
 * @file 工具函数集合
 */

import type {Pagination} from '@/types';

export const isObject = (obj: unknown) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

function leftpad(str: string, len: number, ch = ' ') {
    str = str + '';
    len = len - str.length;
    if (len <= 0) {
        return str;
    }
    ch = ch + '';
    let pad = '';
    while (len) {
        if (len & 1) {
            pad += ch;
        }
        len >>= 1;
        ch += ch;
    }
    return pad + str;
}

/**
 * @file util
 * @param time
 * @param format
 * @returns
 */
export function getTimeStr(time: any, format = 'yyyy-MM-dd HH:mm:ss') {
    if (typeof time === 'string' ||//eslint-disable-line
        typeof time === 'number') {
        time = new Date(time);
        return format
            .replace('yyyy', time.getFullYear())
            .replace('MM', leftpad(time.getMonth() + 1, 2, '0')) //eslint-disable-line
            .replace('dd', leftpad(time.getDate(), 2, '0'))
            .replace('HH', leftpad(time.getHours(), 2, '0'))
            .replace('mm', leftpad(time.getMinutes(), 2, '0'))
            .replace('ss', leftpad(time.getSeconds(), 2, '0'));
    }
    return '';
}

/**
 * @file util
 * @param base
 * @param object
 * @returns string
 */
export function concatQuery(base: string, object: any) {
    return Object.keys(object).reduce((total, key) => {
        total += `${total.includes('?') ? '&' : '?'}${key}=${object[key]}`;
        return total;
    }, base);
}

/**
 * 百分位格式化
 * @param value string 要格式化的金额
 * @return value string
 *  */

export function formatterCentile(amount: string | number) {
    return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

//  千分位
export function thousands(s: string | number, n: number = 2) {
    if (s === '') {
        return;
    }
    else if (!isNaN(parseFloat(s + '')) && isFinite(+s)) {
        n = n > 0 && n < 20 ? n : 2;
        s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
        let l = s.split('.')[0].split('').reverse();
        let r = s.split('.')[1];
        let t = '';
        for (let i = 0; i < l.length; i++) {
            let j = l[i + 1];
            t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length && !isNaN(parseFloat(j)) && isFinite(+j) ? ',' : '');
        }
        return t.split('').reverse().join('') + '.' + r;
    }
};

/**
 * 重置分页数据
 * @param pagination 需要重置的分页对象
 */
export function resetPagination(pagination: Pagination) {
    pagination.pageNum = 1;
    pagination.pageSize = 20;
}

export function getStringLength(str: string) {
    if (typeof str !== 'string') {
        return 0;
    }

    let len = 0;

    for (let i = 0; i < str.length; i++) {
        len += (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) ? 2 : 1;
    }

    return len;
}

export const bdtjCustomPage = (path = '') => {
    try {
        (window as any)._hmt?.push([
            '_trackPageview',
            path,
        ]);
    }
    catch (e) {
        console.log('自定义页面发送失败', e);
    }
};

export const bdtjCustomEvent = (eventName: string, params = {}) => {
    try {
        (window as any)._hmt?.push([
            '_trackCustomEvent',
            eventName,
            params,
        ]);
    }
    catch (e) {
        console.log('自定义事件发送失败', e);
    }
};

export const trackEvent = (category: string, action: string, opt_label?: string, opt_value?: number) => {
    (window as any)._hmt && (window as any)._hmt.push(['_trackEvent', category, action, opt_label, opt_value]);
};

/**
 * base64 转 blob
 * @param base64
 * @param fileType
 * @return blob
 */
const baseToBlob = (base64: any, type: string = 'application/pdf') => {
    let blob = null;
    try {
        // eslint-disable-next-line
        // const type = fileType || /^data\:([^\;]+)\;/g.exec(base64)[1];
        const str = base64.replace(/^data\:([^\;]+)\;base64\,/, '');
        const bytes = window.atob(str);
        const ab = new ArrayBuffer(bytes.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i);
        }
        blob = new Blob([ia], {type});
        return blob;
    }
    catch (error) {
        console.log(error);
    }
};

/**
 * blob 转 url
 * @param blob
 * @returns url
 */
const blobToUrl = (blob: any) => {
    if (!blob) {
        console.log('blob 不能为空');
        return '';
    }
    try {
        const win = window as any || {};
        const _URL = win.URL || win.webkitURL || win.moxURL;
        const url = _URL.createObjectURL(blob);
        return url;
    }
    catch (error) {
        console.log(error);
        return '';
    }
};

/**
 * base64 转 url
 * @param base64
 * @param fileType
 * @returns url
 */
export const baseToUrl = (base64 = '', fileType = 'application/pdf') => {
    if (!base64) {
        console.log('base64 不能为空');
        return '';
        // throw new Error('数据加载失败');
    }
    try {
        const blob = baseToBlob(base64, fileType);
        const url = blobToUrl(blob);
        return url;
    }
    catch (error) {
        console.log(error);
    }
};

/**
 * 格式化金额的函数，千分位，保留两位小数
 * @param amount 需要被格式化的金额 len 保留小数长度
 * @returns 格式化后的值
 */
export function formatAmount(amount: number | string, num: number = 2) {
    if (amount === '-' || amount === null || amount === undefined || amount === '') {
        return '-';
    }
    return new Intl.NumberFormat('en', {
        minimumFractionDigits: num,
        maximumFractionDigits: num,
    }).format(amount as number);
}