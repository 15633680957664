import type {RouteRecordRaw} from 'vue-router';

// 内部管理路由
const routes: RouteRecordRaw[] = [
    {
        path: '/internalManage/userDataPermissions',
        name: 'userDataPermissions',
        meta: {
            label: '用户数据权限',
        },
        component: () => import('@/views/userDataPermissions/apply/index.vue'),
    },
    {
        path: '/internalManage/allocationContract',
        name: 'allocationContract',
        meta: {
            label: '分配合同',
        },
        component: () => import('@/views/userDataPermissions/detail/index.vue'),
    },
];
export default routes;
