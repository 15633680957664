import type {RouteRecordRaw} from 'vue-router';
const routes: RouteRecordRaw[] = [
    {
        path: '/accountView/list',
        name: 'accountViewList',
        meta: {
            label: '账户查看列表',
        },
        component: () => import('@/views/accountAndSelfContract/accountView/index.vue'),
    },
    {
        path: '/accountView/phoenixAccountDetail',
        name: 'phoenixAccountDetail',
        meta: {
            label: '开户列表-查看账户',
        },
        component: () => import('@/views/accountAndSelfContract/accountView/accountOpeningList/detail.vue'),
    },
    {
        path: '/accountView/domainAccountDetail',
        name: 'domainAccountDetail',
        meta: {
            label: '开放域名列表-查看账户',
        },
        component: () => import('@/views/accountAndSelfContract/accountView/openDomainList/detail.vue'),
    },
    {
        path: '/contract/viewChargeDetail',
        name: 'viewChargeDetail',
        meta: {
            label: '充值记录查看',
        },
        component: () => import('@/views/accountAndSelfContract/viewChargeDetail/index.vue'),
    },
];
export default routes;
