import {createPinia, defineStore} from 'pinia';
import {reactive} from 'vue';

// 项目权限统一存储
export const useAuthInfoStore = defineStore('auth', () => {

    const authInfo = reactive<Record<string, any>>({});

    function setAuth(object: Record<string, any>) {

        for (let [k, v] of Object.entries(object || {})) {

            authInfo[k] = v;
        }
    }

    return {authInfo, setAuth};
});

export default createPinia();
