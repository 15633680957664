import type {RouteRecordRaw} from 'vue-router';
const routes: RouteRecordRaw[] = [
    {
        path: '/contract/electronicContractSign',
        name: 'electronicContractSign',
        meta: {
            label: '电子合同签署',
        },
        component: () => import('@/views/contractAndConfig/electronicContractSign/index.vue'),
    },
    {
        path: '/contract/newCharge',
        name: 'newCharge',
        meta: {
            label: '新签充值',
        },
        component: () => import('@/views/contractAndConfig/newCharge/index.vue'),
    },
    {
        path: '/agentConfig',
        name: 'agentConfig',
        meta: {
            label: '代理商配置项管理',
        },
        component: () => import('@/views/contractAndConfig/agentConfig/index.vue'),
    },
];
export default routes;
