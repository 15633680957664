<template>
    <el-config-provider :locale="locale" :size="size">
        <router-view/>
    </el-config-provider>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';

export default defineComponent({
    setup() {
        const locale = ref(zhCn);
        const size = ref<'small' | 'default' | 'large' | undefined>('default');

        return {
            locale,
            size,
        };
    },
});
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
