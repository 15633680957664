// 广告主url设置页面路由
import type {RouteRecordRaw} from 'vue-router';
const routes: RouteRecordRaw[] = [
    // {
    //     path: '/url-manage',
    //     name: 'urlManage',
    //     meta: {
    //         label: '广告主url管理',
    //     },
    //     component: () => import('@/views/urlManageAndContract/urlManage/index.vue'),
    // },
    // {
    //     path: '/url-manage/detail',
    //     name: 'urlManageDetail',
    //     meta: {
    //         label: '广告主url管理详情',
    //     },
    //     component: () => import('@/views/urlManageAndContract/urlManage/detail.vue'),
    // },
];
export default routes;
