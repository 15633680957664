import {createRouter, createWebHashHistory} from 'vue-router';
import type {RouteRecordRaw, RouteLocationNormalized} from 'vue-router';

const modules: Record<string, any> = import.meta.globEager(
    './**/*.ts',
    {
        eager: true,
    } as any
);

// 路由
let routes: RouteRecordRaw[] = [
    // 代理商一站式门户首页
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/partner/views/home/index.vue'),
    },
    // 代理商一站式门户登录
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/partner/views/login/index.vue'),
    },
    // 电商跳转中间页
    {
        path: '/ecommerce-redirect-page',
        name: 'RedirectPage',
        component: () => import('@/views/partner/views/ecommerce-redirect/index.vue'),
    },

    {
        path: '/test-list',
        name: 'testList',
        // route level code-splitting
        component: () => import('@/views/test/list/index.vue'),
    },
    {
        path: '/contractbackreport',
        name: 'contractBackReport',
        // route level code-splitting
        component: () => import('@/views/report/contractBackDetail/index.vue'),
    },
    {
        path: '/guarantee',
        name: 'guarantee',
        component: () => import('@/views/guarantee/index.vue'),
    },
    {
        path: '/account-balance',
        name: 'accountBalance',
        component: () => import('@/views/finance/account-balance/index.vue'),
    },
    // 兜底
    {
        path: '/:pathMatch(.*)',
        redirect: '/',
    },
    // 账期加款
    {
        path: '/paymentPeriod',
        component: () => import('@/views/paymentPeriod/index.vue'),
        name: 'paymentPeriod',
    },
    // 线上协议列表
    {
        path: '/online-protocol/list',
        name: 'onlineProtocolList',
        component: () => import('@/views/online-protocol/list/index.vue'),
    },
];

Object.keys(modules).forEach(key => {
    routes.push(...modules[key].default);
});

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach((to: RouteLocationNormalized) => {
    const label: string = to?.meta?.label as string;
    document.title = label || '代理商平台';
});


export default router;
