/**
 * @file 日志埋点
 */

interface Keys {
    prod: string;
    qa: string;
}


class Hm {
    static keys: Keys = {
        prod: '2188c29772eeba07a9fce63dc27d321c',
        qa: '2188c29772eeba07a9fce63dc27d321c',
    };

    constructor() {
        this.regirst();
    };

    regirst(): Element {

        const env = import.meta?.env?.VITE_APP_MODE === 'production' ? 'prod' : 'qa';

        const hm = document.createElement('script');
        hm.async = true;

        hm.src = `//hm.baidu.com/hm.js?${Hm.keys[env as keyof typeof Hm.keys]}`;
        const s = document.getElementsByTagName('script')[0];

        s.parentNode?.insertBefore(hm, s);

        return hm;
    }

}

export const hm = new Hm();
