import type {RouteRecordRaw} from 'vue-router';
const routes: RouteRecordRaw[] = [
    {
        path: '/contract/applyList',
        name: 'contractApplyList',
        meta: {
            label: '合同列表', // 老的菜单叫做自助合同列表
        },
        component: () => import('@/views/contract/list/apply/index.vue'),
    },
    {
        path: '/contract/edit',
        name: 'contractEdit',
        meta: {
            label: '编辑合同',
        },
        component: () => import('@/views/contract/detail/edit/index.vue'),
    },
    {
        path: '/contract/applyDetail',
        name: 'contractApplyDetail',
        meta: {
            label: '合同详情',
        },
        component: () => import('@/views/contract/detail/edit/index.vue'),
    },
    {
        path: '/contract/versionList',
        name: 'contractVersionList',
        meta: {
            label: '合同列表',
        },
        component: () => import('@/views/contract/list/version/index.vue'),
    },
    {
        path: '/accountRenewList',
        name: 'accountRenewList',
        meta: {
            label: '账户续费列表',
        },
        component: () => import('@/views/renew/account/list/index.vue'),
    },
    {
        path: '/renew/apply',
        name: 'renewApply',
        meta: {
            label: '申请续费',
        },
        component: () => import('@/views/renew/account/apply/index.vue'),
    },
];
export default routes;
